import React, {Component} from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Clutch from "../components/clutch"
import Service__pic from "../images/ser_img.jpg"
import Mojarto from "../images/mojarto.png"
import Buuuk from "../images/buuuk.png"
import Cln from "../images/cln.png"
import ATG_pic from "../images/atg.png"
import Quote_wh from "../images/quotes-wh.png"
import Quote_bk from "../images/quotes-bk.png"
import Paradise_logo from "../images/paradise.png"
import Petdoor_logo from "../images/petdoors-logo.png"
import PlanetJill_logo from "../images/PJLogo_15percent.png"
import MyDAOLabs_logo from "../images/cb_goods/dao_logos.jpg"
import Slider from "react-slick"
import {Helmet} from "react-helmet";
const ListLink = props => (
  <li>
    <Link to={props.to}>{props.children}</Link>
  </li>
)


export default class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }
  componentDidMount() {

    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {


    const settings = {
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            autoplay:true,
            dots: false,
            arrows: true
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: false,
            arrows: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
             arrows: true
          }
        }
      ]
    };
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>CueBlocks eCommerce Marketing and Development Services | Certified Magento 1 and Magento 2 Service Providers </title>
          <link rel="canonical" href="https://www.cueblocks.com/services" />
          <meta name="description"
                content="We offer a wide array of development services and marketing solutions for eCommerce stores running on Magento 1 and Magento 2. Get in touch with us for Magento or PHP support, customization, development, website design, content management and CRO" />
          <meta property="og:site_name" content="Cueblocks" />
          <meta property="fb:app_id" content="289086684439915" />
          <meta property="og:url" content="https://www.cueblocks.com/services.php"/>
          <meta property="og:title" content="CueBlocks eCommerce Marketing and Development Services | Certified Magento 1 and Magento 2 Service Providers" />
          <meta property="og:description" content="We offer a wide array of development services and marketing solutions for eCommerce stores running on Magento 1 and Magento 2. Get in touch with us for Magento or PHP support, customization, development, website design, content management and CRO" />
          <meta property="og:image" content="https://www.cueblocks.com/images/services-og.jpg" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:description" content="We offer a wide array of development services and marketing solutions for eCommerce stores running on Magento 1 and Magento 2. Get in touch with us for Magento or PHP support, customization, development, website design, content management and CRO" />
          <meta name="twitter:title" content="CueBlocks eCommerce Marketing and Development Services | Certified Magento 1 and Magento 2 Service Providers" />
          <meta name="twitter:site" content="@cueblocks" />
          <meta name="twitter:image" content="https://www.cueblocks.com/images/services-og.jpg" />
        </Helmet>
        <div>
          <section className="ser_sec_row service-page ">
            <div className="container">
              <div className="ser_sec_row_lt pd_right_40">
                <h2 className="heading_main">our services</h2>
                <div className="ser_sec_row_lt_text">
                  <p>
                    With a design team that is constantly creating modern, innovative designs, a development team that knows code like their own heartbeat, 
                    and a marketing team that actively walks hand in hand with the latest trends. Just pick a service and we’ll do our best to get your brand the recognition
                     it deserves.
                  </p>
                </div>
                <div className="ser_sec_row_lt_img pd_top_60">
                  <img
                    src={Service__pic}
                    alt=""
                    className="img-responsive hide_mob"
                  />
                </div>
              </div>
              <div className="ser_sec_row_rt pd_left_40">
                <ul>
                  <ListLink to="/magento">Magento</ListLink>
                  <ListLink to="/shopify">Shopify</ListLink>
                  <ListLink to="/php">Php</ListLink>
                  <ListLink to="/creative_design">Creative Design</ListLink>
                  <ListLink to="/organic_search">Organic Search</ListLink>
                  <ListLink to="/paid_search">Digital Advertising</ListLink>
                  <ListLink to="/coversion">Conversion</ListLink>
                </ul>
                <div className="ser_sec_row_lt_text">
                  Not sure where to start, write to us and our consultants will
                  guide you through the process
                </div>
                <Link to="/" className="btn main_cta">
                  Contact us
                </Link>
              </div>
              <div className="border-light"></div>
            </div>
          </section>

          <section className="ser_sec_row clients_section">
            <div className="container">
              <div className="ser_sec_row_lt pd_right_40">
                <h2 className="heading_main">Happy Clientele, Happy CueTeam</h2>
              </div>
              <div className="ser_sec_row_rt pd_left_40">
                <p>
                  The success of a company = The happiness of its clients. 
                  <br /> 
                 Have you heard what our clients say about us?
                </p>
              </div>
              <div className="ser_sec_row_slider">
                <div className="brand_slider">
                  <Slider {...settings}>
                    <div>
                      <div className="logos-brand">
                        <img src={Mojarto} alt="Mojarto logo" />
                      </div>
                    </div>
                    <div>
                      <div className="logos-brand">
                        <img src={Buuuk} alt="Buuuk logo" />
                      </div>
                    </div>
                    <div>
                      <div className="logos-brand">
                        <img src={Cln} alt="clnwash logo" />
                      </div>
                    </div>
                    <div>
                      <div className="logos-brand">
                        <img src={ATG_pic} alt="Atg logo" />
                      </div>
                    </div>
                    <div>
                      <div className="logos-brand">
                        <img src={Paradise_logo} alt="ParadiseGalleries logo" />
                      </div>
                    </div>
                    <div>
                      <div className="logos-brand">
                        <img src={Petdoor_logo} alt="Petdoor Logo" />
                      </div>
                    </div>
                    <div>
                      <div className="logos-brand">
                        <img src={PlanetJill_logo} alt="PlanetJill logo" />
                      </div>
                    </div>
                    <div>
                      <div className="logos-brand">
                        <img src={MyDAOLabs_logo} alt="MyDAOLabs logo" />
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </section>

          <Clutch />
        </div>
      </Layout>
    )

  }
}

